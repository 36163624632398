import React, { useState } from "react";

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="bg-red-700/10 backdrop-blur p-5 fixed w-full top-0 z-50 shadow-md">
      <div className="flex justify-between items-center max-w-6xl mx-auto px-5">
        <div className="logo">
          <img src="logo.png" alt="TaxGPT Logo" className="w-16 h-auto" />
        </div>
        <ul
          className={`nav-links list-none flex-col space-y-5 md:flex md:flex-row md:space-y-0 md:space-x-8 text-white ${isOpen ? "flex" : "hidden"}`}
        >
          {/* <li>
            <a href="#about" className="hover:text-red-300 hover:underline">
              About
            </a>
          </li> */}
          <li>
            <a
              href="#how-it-works"
              className="hover:text-red-300 hover:underline"
            >
              How It Works
            </a>
          </li>
          <li>
            <a href="#features" className="hover:text-red-300 hover:underline">
              Features
            </a>
          </li>
          {/* <li>
            <a href="#join-now" className="hover:text-red-300 hover:underline">
              Join Now
            </a>
          </li> */}
        </ul>
        <button
          onClick={toggleMenu}
          className="menu-toggle md:hidden bg-transparent border-none text-white text-2xl"
        >
          &#9776;
        </button>
      </div>
    </nav>
  );
}

export default Navbar;
