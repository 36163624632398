import React from "react";

function Features() {
  return (
    <section
      id="features"
      className="py-20 px-5 bg-gradient-to-r from-blue-800 to-red-700 text-white text-center"
    >
      <div className="max-w-2xl mx-auto">
        <h2 className="text-4xl font-bold mb-10">Key Features</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 justify-around gap-5 ">
          <div className="flex-1 max-w-xs bg-white bg-opacity-10 rounded-lg p-5 transition-transform duration-300 ease-in-out transform hover:scale-105 hover:shadow-lg hover:bg-opacity-20">
            <h3 className="text-xl font-bold mb-3">Seamless Integration</h3>
            <p>
              Connect with multiple banking platforms without hassle for a
              streamlined experience.
            </p>
          </div>
          <div className="flex-1 max-w-xs bg-white bg-opacity-10 rounded-lg p-5 transition-transform duration-300 ease-in-out transform hover:scale-105 hover:shadow-lg hover:bg-opacity-20">
            <h3 className="text-xl font-bold mb-3">Real-Time Analytics</h3>
            <p>
              Get real-time insights into your financial health, enabling better
              decision making.
            </p>
          </div>
          <div className="flex-1 max-w-xs bg-white bg-opacity-10 rounded-lg p-5 transition-transform duration-300 ease-in-out transform hover:scale-105 hover:shadow-lg hover:bg-opacity-20">
            <h3 className="text-xl font-bold mb-3">Automated Reporting</h3>
            <p>
              Automate the generation of detailed tax reports to simplify your
              tax filing process.
            </p>
          </div>
          <div className="flex-1 max-w-xs bg-white bg-opacity-10 rounded-lg p-5 transition-transform duration-300 ease-in-out transform hover:scale-105 hover:shadow-lg hover:bg-opacity-20">
            <h3 className="text-xl font-bold mb-3">Secure Data Protection</h3>
            <p>
              Top-level security measures ensure that your financial data is
              always protected.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Features;
