import React, { useEffect, useRef } from "react";
import * as THREE from "three";

const HeroSection = () => {
  const mountRef = useRef(null);

  useEffect(() => {
    let scene, camera, renderer, nodeMesh, lineMesh;
    const nodes = [];
    const maxConnections = 20;
    const nodeCount = 200; // Number of nodes

    const init = () => {
      scene = new THREE.Scene();
      camera = new THREE.PerspectiveCamera(
        75,
        window.innerWidth / window.innerHeight,
        0.1,
        1000
      );
      camera.position.z = 500;

      renderer = new THREE.WebGLRenderer({ antialias: true });
      renderer.setSize(window.innerWidth, window.innerHeight);
      mountRef.current.appendChild(renderer.domElement);

      const nodeGeometry = new THREE.BufferGeometry();
      const nodePositions = new Float32Array(nodeCount * 3);
      for (let i = 0; i < nodeCount * 3; i += 3) {
        nodePositions[i] = Math.random() * 800 - 400;
        nodePositions[i + 1] = Math.random() * 800 - 400;
        nodePositions[i + 2] = Math.random() * 800 - 400;

        nodes.push({
          x: nodePositions[i],
          y: nodePositions[i + 1],
          z: nodePositions[i + 2],
          connections: 0,
        });
      }

      nodeGeometry.setAttribute(
        "position",
        new THREE.BufferAttribute(nodePositions, 3)
      );
      const nodeMaterial = new THREE.PointsMaterial({
        color: 0xff0000, // Red color for nodes
        size: 3,
      });
      nodeMesh = new THREE.Points(nodeGeometry, nodeMaterial);
      scene.add(nodeMesh);

      const lineGeometry = new THREE.BufferGeometry();
      const linePositions = new Float32Array(nodeCount * maxConnections * 3);
      lineGeometry.setAttribute(
        "position",
        new THREE.BufferAttribute(linePositions, 3)
      );
      const linesMaterial = new THREE.LineBasicMaterial({
        color: 0xff0000, // Red color for lines
        transparent: true,
        opacity: 0.3,
      });
      lineMesh = new THREE.LineSegments(lineGeometry, linesMaterial);
      scene.add(lineMesh);

      animate();
    };

    const updateNodes = () => {
      const nodePositions = nodeMesh.geometry.attributes.position.array;
      const linePositions = lineMesh.geometry.attributes.position.array;
      let idx = 0;

      for (let i = 0; i < nodeCount; i++) {
        nodePositions[i * 3] += Math.sin(i * 0.05) * 0.1;
        nodePositions[i * 3 + 1] += Math.cos(i * 0.05) * 0.1;

        for (let j = i + 1; j < nodeCount; j++) {
          const dx = nodes[i].x - nodes[j].x;
          const dy = nodes[i].y - nodes[j].y;
          const dz = nodes[i].z - nodes[j].z;
          const dist = Math.sqrt(dx * dx + dy * dy + dz * dz);

          if (dist < 150) {
            linePositions[idx++] = nodes[i].x;
            linePositions[idx++] = nodes[i].y;
            linePositions[idx++] = nodes[i].z;
            linePositions[idx++] = nodes[j].x;
            linePositions[idx++] = nodes[j].y;
            linePositions[idx++] = nodes[j].z;
          }
        }
      }

      nodeMesh.geometry.attributes.position.needsUpdate = true;
      lineMesh.geometry.attributes.position.needsUpdate = true;
    };

    const animate = () => {
      requestAnimationFrame(animate);
      updateNodes();
      renderer.render(scene, camera);
    };

    init();

    // Cleanup on component unmount
    return () => {
      mountRef.current.removeChild(renderer.domElement);
    };
  }, []);

  const handleRedirect = (question) => {
    // Redirect to the subdomain with the question
    const encodedQuestion = encodeURIComponent(question);
    window.location.href = `https://app.taxgpt.ae/?q=${encodedQuestion}`;
  };

  return (
    <section className="relative h-screen">
      <div ref={mountRef} className="absolute inset-0"></div>
      <div className="relative z-10 flex flex-col items-center justify-center h-full text-center text-white px-6">
        <h2 className="text-3xl md:text-4xl font-bold mb-4">
          Your Tax AI Assistant
        </h2>
        <h1 className="text-4xl md:text-6xl font-extrabold mb-6">
          <span className="text-red-500">TaxGPT</span> - Simplifying Tax
          Calculations
        </h1>
        <p className="text-lg md:text-xl mb-8">
          Using AI to automate and simplify your tax reporting and filing.
        </p>

        {/* Frequently asked questions */}
        <div className="space-y-2 md:space-y-0 md:grid md:grid-cols-2 md:gap-4 mb-8">
          <button
            onClick={() => handleRedirect("Tax info for free zone companies")}
            className="bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-600 focus:ring-opacity-50"
          >
            Tax info for free zone companies
          </button>
          <button
            onClick={() => handleRedirect("Mainland company corporate tax")}
            className="bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-600 focus:ring-opacity-50"
          >
            Mainland company corporate tax
          </button>
          <button
            onClick={() => handleRedirect("Freelancer corporate tax")}
            className="bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-600 focus:ring-opacity-50"
          >
            Freelancer corporate tax
          </button>
          <button
            onClick={() => handleRedirect("Book Free Consultation")}
            className="bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-600 focus:ring-opacity-50"
          >
            Book Free Consultation
          </button>
        </div>

        {/* Search Bar */}
        <form
          action="https://taxgptae.vercel.app/"
          className="w-full max-w-md mx-auto flex bg-white rounded-md overflow-hidden"
        >
          <input
            type="text"
            name="q"
            placeholder="Ask TaxGPT a question"
            className="flex-1 px-4 py-2 text-black outline-none"
          />
          <button
            type="submit"
            className="bg-red-500 px-4 py-2 hover:bg-red-600 text-white"
          >
            <span className="search-icon">→</span>
          </button>
        </form>
      </div>
    </section>
  );
};

export default HeroSection;
