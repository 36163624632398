import React from "react";

function HowItWorks() {
  return (
    <section
      id="how-it-works"
      className="bg-gradient-to-b from-gray-800 to-gray-600 text-white py-20 px-10"
    >
      <div className="max-w-4xl mx-auto text-center">
        <h2 className="text-4xl font-bold mb-10">How It Works</h2>
        <div className="space-y-8">
          <div className="flex flex-col items-center">
            <div className="flex items-center justify-center w-16 h-16 rounded-full bg-red-500 text-white text-2xl mb-4">
              1
            </div>
            <h3 className="text-2xl font-bold">Connect Your Bank Account</h3>
            <p className="text-lg">
              Link your bank account securely to track your expenses
              automatically.
            </p>
          </div>

          <div className="h-0.5 w-1/12 bg-red-500 mx-auto my-4"></div>

          <div className="flex flex-col items-center">
            <div className="flex items-center justify-center w-16 h-16 rounded-full bg-red-500 text-white text-2xl mb-4">
              2
            </div>
            <h3 className="text-2xl font-bold">AI Identifies Deductions</h3>
            <p className="text-lg">
              AI identifies potential tax deductions based on your transactions.
            </p>
          </div>

          <div className="h-0.5 w-1/12 bg-red-500 mx-auto my-4"></div>

          <div className="flex flex-col items-center">
            <div className="flex items-center justify-center w-16 h-16 rounded-full bg-red-500 text-white text-2xl mb-4">
              3
            </div>
            <h3 className="text-2xl font-bold">File Taxes Easily</h3>
            <p className="text-lg">
              Generate detailed reports and file your taxes automatically.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default HowItWorks;
